.toast {
  padding: 14px 24px;
  line-height: 20px;
  color: #fff;
  background: $toaster-bg;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  transition: 0.3s;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
  z-index: $zindex-toast;
  min-width: 288px;
  max-width: 80%;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;

  &.reveal {
    transform: translateX(-50%) translateY(0);
  }

  .text {
    flex-basis: 1;
    font-weight: 400;
    white-space: nowrap;
    margin-right: 48px;
  }

  .action {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.875rem;

    a:hover {
      text-decoration: none;
    }
  }
}
