//-----------------------------------------------------------------
// Article
//
.article {
  a {
    color: $color-info;
  }

  .lead {
    font-size: 17px;
    color: $color-text-light;
    opacity: 0.8;
  }

  p {
    font-size: 15px;
    line-height: $line-height-md + 4px;
    letter-spacing: 0.1px;
  }

  b,
  strong {
    font-weight: 400;
  }

  blockquote {
    margin: 40px 0;

    p {
      font-size: 18px;
    }
  }
}

.container-article {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

@include media-down(sm) {
  .article {
    .lead {
      font-size: 15px;
    }

    p {
      font-size: 13px;
      line-height: $line-height-md;
    }

    blockquote p {
      font-size: 15px;
    }
  }
}
