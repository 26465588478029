.mapael {
  .map {
    position: relative;
  }

  .zoomButton {
    background-color: #fff;
    border: 1px solid $color-divider-light;
    color: $color-text;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    top: 0;
    font-weight: bold;
    left: 10px;
    user-select: none;
    transition: 0.2s linear;

    &:hover {
      background-color: $color-bg-lighter;
    }
  }

  /* Reset Zoom button first */
  .zoomReset {
    top: 10px;
  }

  /* Then Zoom In button */
  .zoomIn {
    top: 34px;
  }

  /* Then Zoom Out button */
  .zoomOut {
    top: 58px;
  }

  .mapTooltip {
    position: absolute;
    background-color: #474c4b;
    opacity: 0.7;
    border-radius: 3px;
    padding: 4px 8px;
    max-width: 200px;
    display: none;
    color: #fff;
    z-index: 1000;
  }
}
