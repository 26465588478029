.form-check-input {
  outline: none !important;
}

//-----------------------------------------------------------------
// Custom checkbox and radiobox
//
.custom-control {
  color: $color-text;
  font-weight: 300;
  letter-spacing: 0;
  margin-bottom: 0;
  outline: none !important;

  display: inline-flex;
  align-items: center;
  padding-left: 0;
}

.custom-controls-stacked {
  @include clearfix();

  .custom-control {
    display: inline-flex;
    margin-bottom: 6px;
  }
}

.custom-control-input {
  &:checked ~ .custom-control-indicator,
  &:active:not(:disabled) ~ .custom-control-indicator {
    background-image: none !important;
    background-color: $color-bg-lightest;
  }

  &:focus ~ .custom-control-indicator {
    box-shadow: none;
  }

  &:checked ~ .custom-control-indicator {
    &::before,
    &::after {
      transform: scale(1);
    }
  }

  &:disabled ~ .custom-control-indicator {
    opacity: 0.5;
  }

  &:checked ~ .custom-control-description.strike-on-check {
    text-decoration: line-through;
    opacity: 0.6;
  }
}

.custom-control-indicator {
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: #fff;
  background-color: $color-bg-lightest;
  border: 1px solid $color-divider;

  position: static;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;

  &::after {
    content: '';
    display: inline-block;
    transform: scale(0);
  }
}

.custom-control-description {
  padding-left: 8px;
  flex-grow: 1;
}

// Radio only
//
.custom-radio {
  cursor: pointer;

  .custom-control-indicator::after {
    border-radius: 50%;
    vertical-align: top;
    margin-top: 4px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: $color-primary;
  }
}

// Checkbox only
//
.custom-checkbox {
  cursor: pointer;

  .custom-control-indicator {
    border-radius: 0;

    &::after {
      content: '\e64c';
      font-family: $font-icon-themify;
      font-size: 11px;
      font-weight: bold;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      color: $color-primary;
    }
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    &::before,
    &::after {
      border-radius: 0;
    }
  }
}

// No border
//
.custom-control.no-border {
  .custom-control-indicator {
    border-color: transparent;
    background-color: transparent !important;

    &::before {
      background-color: transparent;
    }
  }

  &.custom-checkbox .custom-control-indicator {
    &::after {
      font-size: 14px;
    }
  }

  &.custom-radio .custom-control-indicator {
    &::after {
      margin-top: 3px;
      display: inline-block;
      width: 9px;
      height: 9px;
    }
  }
}

// Sizes
//
.custom-control-sm {
  .custom-control-indicator {
    width: 14px;
    height: 14px;
    line-height: 12px;
  }

  &.custom-radio .custom-control-indicator::after {
    margin-top: 3px;
    width: 6px;
    height: 6px;
  }
  &.custom-radio.no-border .custom-control-indicator::after {
    margin-top: 2px;
    width: 7px;
    height: 7px;
  }

  &.custom-checkbox .custom-control-indicator::after {
    font-size: 9px;
  }
  &.custom-checkbox.no-border .custom-control-indicator::after {
    font-size: 11px;
  }
}

.custom-control-lg {
  .custom-control-indicator {
    width: 22px;
    height: 22px;
    line-height: 22px;
  }

  &.custom-radio .custom-control-indicator::after {
    margin-top: 5px;
    width: 10px;
    height: 10px;
  }
  &.custom-radio.no-border .custom-control-indicator::after {
    margin-top: 5px;
    width: 10px;
    height: 10px;
  }

  &.custom-checkbox .custom-control-indicator::after {
    font-size: 14px;
  }
  &.custom-checkbox.no-border .custom-control-indicator::after {
    font-size: 16px;
  }
}

//
// Color variations
//
.custom-control-light .custom-control-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}

@each $name, $color in $colors {
  .custom-control-#{$name} {
    @include custom-control-colors($color);
  }
}
.custom-control-light {
  @include custom-control-colors(#fff);
}

.custom-control-secondary .custom-control-indicator::after {
  color: $color-text;
}
.custom-control-light .custom-control-indicator::after {
  color: $color-primary;
}
.custom-control-light.custom-radio .custom-control-indicator::after {
  background-color: $color-primary;
}

//
// Validation
//
.custom-control-input.is-invalid ~ .custom-control-indicator,
.was-validated .custom-control-input:invalid ~ .custom-control-indicator,
.custom-control-input.is-valid ~ .custom-control-indicator,
.was-validated .custom-control-input:valid ~ .custom-control-indicator {
  background-color: $color-bg-lightest;
}

.custom-control-input.is-invalid {
  ~ .custom-control-description {
    color: $color-danger;
  }

  ~ .invalid-feedback {
    display: flex;
    width: 100%;
    padding-left: 1rem;
    font-size: 12px;
    margin-top: 0;
    font-style: italic;

    &::before {
      content: '\2014 \00A0';
    }
  }
}

.custom-control-input.is-valid {
  ~ .custom-control-description {
    color: $color-success;
  }
}
