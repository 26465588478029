.lity-close {
  font-weight: 300;
  opacity: 0.6;
  transition: 0.5s;

  &:hover,
  &:focus,
  &:active {
    font-weight: 300;
    opacity: 1;
  }
}
