.list-group {
  .badge-pill {
    margin-top: 3px;
  }

  &.bordered {
    border: 1px solid $color-divider;
  }
}

.list-group-item {
  font-weight: 0.875rem;
  border: 0;
  border-bottom: 1px solid lighten($color-divider, 3%);
  margin-bottom: 0;
  border-radius: 0 !important;

  &.active,
  &.active:hover,
  &.active:focus {
    background-color: $color-primary;
    border-color: $color-primary;

    .list-group-item-text {
      color: #fff;
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  .media {
    margin-bottom: 0;
  }
}

.list-group-item-heading {
  font-weight: 400;
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  background-color: $color-bg-lighter;
}
