//==================
// Topbar
//==================

// Fix movement on openning modal
@include media-up(lg) {
  .modal-open .topbar {
    padding-right: 37px;
  }
}

//==================
// Main
//==================
html,
body {
  height: 100%;
}

body #root {
  position: relative;
  min-height: 100%;
  overflow: hidden;
}

body .main-container,
body > main,
body > div > main {
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease-out;
}

//==================
// Main content
//==================
.main-content {
  padding: 0px 30px;
  flex: 1 0 auto;

  &.container {
    padding-left: 15px;
    padding-right: 15px;
  }

  > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.container-full {
  margin-left: -30px;
  margin-right: -30px;
}

//==================
// Footer
//==================
.site-footer {
  padding: 15px 30px;
}

//==================
// Backdrop
//==================
.app-backdrop {
  display: none;
}

//==================
// Small devices
//==================
@include media-up(md) {
  .card-maximize {
    left: $width-sidebar-normal;
  }
}

@include media-down(lg) {
}

@include media-down(md) {
  .topbar,
  body .main-container,
  body > main,
  body > div > main {
    margin-left: 0;
  }

  .card-maximize {
    left: 0;
  }

  // Site footer
  .site-footer,
  .site-footer .container {
    flex-direction: column;
  }
}

@include media-down(sm) {
  .topbar {
    padding: 0 0.25rem;
  }

  .site-footer,
  .main-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container-full {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .printing-area {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;

    * {
      visibility: visible;
    }
  }
}
