.color-selector {
  display: inline-block;

  label {
    position: relative;
    display: inline-block;
    width: $height-sm;
    height: $height-sm;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 4px;
    margin-bottom: 4px;

    &.inverse {
      border: 1px solid $color-bg-dark;
      span::after {
        color: $color-text;
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;

    &::after {
      content: '\e64c';
      font-family: $font-icon-themify;
      font-size: 1rem;
      font-weight: bold;
      display: block;
      color: #fff;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(0, 0);
      transition: 0.2s;
    }
  }

  input {
    opacity: 0;

    &:checked ~ span::after {
      transform: translateY(-50%) scale(1, 1);
    }
  }
}

// Sizes
//
.color-selector-sm {
  label {
    width: $height-xs;
    height: $height-xs;
  }

  span::after {
    font-size: 0.875rem;
  }
}

.color-selector-lg {
  label {
    width: $height-md;
    height: $height-md;
  }

  span::after {
    font-size: 1.125rem;
  }
}
