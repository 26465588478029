.swal2-modal {
  .swal2-title {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: $color-text;
  }

  .swal2-content {
    color: $color-text;
    font-size: 1rem;
  }

  .swal2-confirm,
  .swal2-cancel {
    min-width: 80px;
  }

  button + button {
    margin-left: 12px;
  }
}
