@mixin tab-variant($color) {
  .nav-link {
    &:hover,
    &.active,
    &.active:focus,
    &.active:hover {
      border-color: $color;
    }
  }
}
