.modal-content {
  border-radius: 3px;
  border: none;
}

.modal-header {
  border-bottom-color: $color-divider-light;

  &.no-border {
    margin-bottom: 1rem;
  }
}

.modal-title {
  font-weight: 400;
  letter-spacing: 0.5px;
}

.modal-footer {
  border: none;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.modal[data-backdrop='false']:not(.modal-fill) .modal-content {
  border: 1px solid $color-divider-light;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.06);
}

//-----------------------------------------------------------------
// Modal top
//
.modal-top {
  opacity: 1;

  &.show .modal-dialog {
    transform: translateY(0) !important;
  }

  .modal-dialog {
    margin-top: 0;
    transform: translateY(-100%) !important;
  }

  .modal-content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

//-----------------------------------------------------------------
// Modal center
//
.modal-center {
  transform: scale(0);
  transition: 0.4s;

  &.show {
    transform: scale(1);
  }

  .modal-dialog {
    margin: 0;
    width: 100%;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%) !important;
  }
}

//-----------------------------------------------------------------
// Modal bottom
//
.modal-bottom {
  opacity: 1;
  overflow-y: hidden !important;

  &.show .modal-dialog {
    transform: translate(-50%, 0) !important;
  }

  .modal-dialog {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 100%) !important;
    transition: 0.4s;
  }

  .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

//-----------------------------------------------------------------
// Modal left
//
.modal-left {
  opacity: 1;

  &.show .modal-dialog {
    transform: translate(0, 0) !important;
  }

  .modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 360px;
    max-width: 100%;
    margin: 0;
    transform: translate(-100%, 0) !important;
    transition: 0.5s;
  }

  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }

  .modal-body {
    flex-grow: 1;
  }
}

//-----------------------------------------------------------------
// Modal right
//
.modal-right {
  opacity: 1;

  &.show .modal-dialog {
    transform: translate(0, 0) !important;
  }

  .modal-dialog {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 360px;
    max-width: 100%;
    margin: 0;
    transform: translate(100%, 0) !important;
    transition: 0.5s;
  }

  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
  }

  .modal-body {
    flex-grow: 1;
  }
}

//-----------------------------------------------------------------
// Modal fill
//
.modal-fill {
  background: rgba(255, 255, 255, 0.97);
  transform: scale(0, 0);
  transition: 0.4s;

  &.show {
    display: flex !important;
    justify-content: center;
    flex-flow: column nowrap;
    transform: scale(1, 1);
    transition: 0.4s;
  }

  .modal-dialog {
    display: flex;
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    justify-content: center;
    flex-flow: column nowrap;
    align-content: center;
    align-items: center;
  }

  .modal-content {
    background: transparent;
    width: 100%;
    max-width: 600px;
  }

  .modal-header {
    border-bottom: none;

    .close {
      position: fixed;
      top: 0;
      right: 0;
      padding: 20px;
      font-size: 2.5rem;
      font-weight: 300;
    }
  }
}

//-----------------------------------------------------------------
// Media queries
//
@include media-up(sm) {
  .modal-sm {
    width: 360px;
    max-width: 360px;
  }
}
