.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.react-bs-container-body {
  color: #8b95a5;
}

.react-bs-table-bordered {
  border-color: #ebebeb;
  border-radius: 2px;
}

.react-bs-table .table-bordered > tbody > tr > td {
  border-bottom: 0;
}
