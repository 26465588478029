@mixin form-validation-colors($color) {
  .form-control {
    border-color: $color;

    &:focus {
      box-shadow: none;
    }
  }

  .checkbox,
  .checkbox-inline,
  .custom-control,
  .form-control-feedback,
  .form-control-label,
  .radio,
  .radio-inline,
  &.checkbox label,
  &.checkbox-inline label,
  &.radio label,
  &.radio-inline label {
    color: $color;
  }
}

@mixin custom-control-colors($color) {
  &.custom-checkbox .custom-control-indicator::after {
    color: $color;
  }

  &.custom-radio .custom-control-indicator::after {
    background-color: $color;
  }
}
