/* Forms */

/* Fix global problem where form groups do not display validation feedback */
.form-group.has-error .invalid-feedback {
  display: block;
}

/* Fix bug where material UI field labels get superimposed with autofill text */
input:-webkit-autofill + label {
  top: -8px !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  opacity: 0.5;
}

/* Typography */
.text-gray {
  color: #8b95a5 !important;
}
.subtitle {
  color: #8b95a5 !important;
  opacity: 1 !important;
  font-weight: 300;
}

.form-group label {
  font-size: 14px;
}

.form-group small {
  font-size: 14px !important;
  letter-spacing: 0.3px;
  font-weight: 300px;
  line-height: 32px !important;
}
