//-----------------------------------------------------------------
// Process
//
.nav-process {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;

  .nav-title {
    font-weight: 400;
  }

  .nav-item {
    align-items: center;
  }

  .nav-link {
    padding: 0;
  }
}

//-----------------------------------------------------------------
// Circle process
//
.nav-process-circle {
  .nav-item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: $color-text-secondary;
    padding: 0 12px;

    &:first-child .nav-link::before {
      display: none;
    }

    &.complete,
    &.processing {
      .nav-link {
        &,
        &::before {
          background-color: $color-pale-primary;
        }

        &::after {
          background-color: $color-primary;
          width: $height-sm;
          height: $height-sm;
          transform: translateX(0);
          color: #fff;
        }
      }
    }

    &.complete .nav-link::after {
      width: $height-sm;
      height: $height-sm;
      transform: translateX(0);
      color: #fff;
    }

    &.processing {
      color: $color-text;

      .nav-link::after {
        width: $height-sm - 16px;
        height: $height-sm - 16px;
        margin-top: 8px;
        transform: translateX(8px);
        color: transparent;
      }
    }
  }

  .nav-link {
    display: inline-flex;
    margin: 10px 0;
    width: $height-sm;
    height: $height-sm;
    max-height: $height-sm;
    border-radius: 50%;
    background-color: $color-pale-secondary;
    transition: 0.5s;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      left: calc(-50% + #{$height-sm * 0.5});
      right: calc(50% + #{$height-sm * 0.5});
      height: 10px;
      margin-top: $height-sm * 0.5 - 5px;
      background-color: $color-pale-secondary;
      cursor: default;
      transition: 0.5s;
    }

    &::after {
      content: '\e64c';
      font-family: $font-icon-themify;
      width: 0;
      height: 0;
      text-align: center;
      font-size: 15px;
      position: absolute;
      border-radius: 50%;
      background-color: transparent;
      color: transparent;
      transform: translate($height-sm * 0.5, $height-sm * 0.5);
      transition: 0.5s;
      z-index: 1;

      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//-----------------------------------------------------------------
// Iconic process
//
.nav-process-iconic {
  .nav-item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    color: $color-text-secondary;
    padding: 0 12px;

    i {
      color: $color-text-secondary;
    }

    &:first-child .nav-link::before {
      display: none;
    }

    &.complete,
    &.processing {
      .nav-link::before {
        background-color: $color-primary;
      }
    }

    &.complete {
      i {
        color: #fff;
      }

      .nav-link {
        background-color: $color-primary;
      }
    }

    &.processing {
      color: $color-text;

      i {
        color: $color-primary;
      }

      .nav-link {
        border-color: $color-primary;
        background-color: #fff;
      }
    }
  }

  .nav-link {
    font-size: 22px;
    justify-content: center !important;
    margin: 10px 0;
    width: $height-lg;
    height: $height-lg;
    line-height: $height-lg - 4px;
    max-height: $height-lg;
    border-radius: 50%;
    border: 2px solid transparent;
    background-color: $color-pale-secondary;
    color: inherit;

    &::before {
      content: '';
      position: absolute;
      left: calc(-50% + #{$height-lg * 0.5});
      right: calc(50% + #{$height-lg * 0.5});
      height: 3px;
      margin-top: $height-lg * 0.5 - 1.5px;
      background-color: $color-pale-secondary;
      cursor: default;
    }
  }
}

//-----------------------------------------------------------------
// Box process
//
.nav-process-box {
  margin-bottom: 0;

  .nav-item {
    display: flex;
    flex: 1;
    border-right: 1px solid $color-divider-light;

    &::after {
      content: '\e649';
      font-family: $font-icon-themify;
      color: $color-divider-light;
      background-color: #fff;
      margin-right: -10px;
      line-height: 1;
    }

    &:last-child {
      border-right: none;

      &::after {
        display: none;
      }
    }

    &.complete:not(.processing) .nav-link {
      .nav-link-number {
        color: #fff;
        background-color: $color-primary;

        i {
          color: #fff;
        }
      }
    }

    &.processing {
      .nav-link-number {
        background-color: $color-dark;
        color: #fff;
      }

      .nav-title {
        color: $color-dark;
      }
    }
  }

  .nav-link {
    padding: 12px 20px;
    width: 100%;
  }

  .nav-link-number {
    $size: $height-sm;
    display: inline-block;
    max-width: $size;
    width: $size;
    height: $size;
    line-height: $size;
    font-size: 15px;
    font-weight: 400;
    border-radius: 50%;
    background-color: $color-pale-secondary;
    color: $color-text-secondary;
    text-align: center;

    i {
      font-size: 12px;
    }
  }

  .nav-title {
  }
}

//-----------------------------------------------------------------
// Block process
//
.nav-process-block {
  .nav-item {
    display: flex;
    flex: 1;
    color: $color-text-secondary;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }

    &.complete:not(.processing) .nav-link {
      background-color: $color-primary;
      color: #fff;

      .nav-link-number {
        color: $color-primary;
        font-weight: 400;
      }
    }

    &.processing .nav-link-number {
      background-color: $color-primary;
      color: #fff;
    }
  }

  .nav-link {
    display: flex;
    width: 100%;
    background-color: $color-pale-secondary;
    padding: 20px;
  }

  .nav-link-number {
    max-width: $height-lg;
    width: $height-lg;
    height: $height-lg;
    line-height: $height-lg;
    font-size: 28px;
    font-weight: 300;
    border-radius: 50%;
    background-color: #fff;
    color: $color-text-secondary;
    text-align: center;
    flex-shrink: 0;
  }

  .nav-link-body {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    white-space: normal;
    padding-left: 12px;
    line-height: 1.25rem;
    font-weight: 300;
    white-space: nowrap;
  }

  .nav-title {
    margin-bottom: 10px;
    font-size: 1rem;
  }

  @include media-down(sm) {
    .nav-link {
      padding: 10px;
      flex-direction: column;
      align-items: center;
    }

    .nav-link-number {
      transform: scale(0.6);
    }

    .nav-link-body {
      text-align: center;
      margin-left: 0 !important;
      padding-left: 0;
      margin-top: 10px;
    }
  }
}

//-----------------------------------------------------------------
// Sizes
//

// Small
//
.nav-process-sm {
  &.nav-process-circle {
    $size: $height-xs;

    .nav-item {
      &.complete,
      &.processing {
        .nav-link {
          &::after {
            width: $size;
            height: $size;
            line-height: $size;
          }
        }
      }

      &.complete .nav-link::after {
        width: $size;
        height: $size;
      }

      &.processing {
        .nav-link::after {
          width: $size - 16px;
          height: $size - 16px;
          margin-top: 8px;
          transform: translateX(8px);
        }
      }
    }

    .nav-link {
      margin: 10px 0;
      width: $size;
      height: $size;
      max-height: $size;

      &::before {
        left: calc(-50% + #{$size * 0.5});
        right: calc(50% + #{$size * 0.5});
        height: 10px;
        margin-top: $size * 0.5 - 5px;
      }

      &::after {
        font-size: 12px;
      }
    }
  }

  &.nav-process-iconic {
    $size: $height-md;

    .nav-link {
      font-size: 18px;
      width: $size;
      height: $size;
      line-height: $size - 4px;
      max-height: $size;

      &::before {
        left: calc(-50% + #{$size * 0.5});
        right: calc(50% + #{$size * 0.5});
        margin-top: $size * 0.5 - 1.5px;
      }
    }
  }

  &.nav-process-box {
    $size: $height-xs;

    .nav-link {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .nav-link-number {
      max-width: $size;
      width: $size;
      height: $size;
      line-height: $size;
      font-size: 12px;

      i {
        font-size: 10px;
      }
    }

    .nav-title {
      font-size: 12px;
    }
  }

  &.nav-process-block {
    $size: $height-md;

    .nav-link {
      padding: 16px;
    }

    .nav-link-number {
      max-width: $size;
      width: $size;
      height: $size;
      line-height: $size;
      font-size: 24px;
    }

    .nav-link-body {
      line-height: 1rem;
    }

    .nav-title {
      font-size: 0.9375rem;
    }
  }
}

// Large
//
.nav-process-lg {
  &.nav-process-circle {
    $size: $height-md;

    .nav-item {
      &.complete,
      &.processing {
        .nav-link {
          &::after {
            width: $size;
            height: $size;
            line-height: $size;
          }
        }
      }

      &.complete .nav-link::after {
        width: $size;
        height: $size;
      }

      &.processing {
        .nav-link::after {
          width: $size - 22px;
          height: $size - 22px;
          margin-top: 11px;
          transform: translateX(11px);
        }
      }
    }

    .nav-link {
      margin: 10px 0;
      width: $size;
      height: $size;
      max-height: $size;

      &::before {
        left: calc(-50% + #{$size * 0.5});
        right: calc(50% + #{$size * 0.5});
        height: 10px;
        margin-top: $size * 0.5 - 5px;
      }

      &::after {
        font-size: 18px;
      }
    }
  }

  &.nav-process-iconic {
    $size: $height-xl;

    .nav-link {
      font-size: 28px;
      width: $size;
      height: $size;
      line-height: $size - 4px;
      max-height: $size;

      &::before {
        left: calc(-50% + #{$size * 0.5});
        right: calc(50% + #{$size * 0.5});
        margin-top: $size * 0.5 - 1.5px;
      }
    }
  }

  &.nav-process-box {
    $size: $height-md;

    .nav-link {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .nav-link-number {
      max-width: $size;
      width: $size;
      height: $size;
      line-height: $size;
      font-size: 16px;

      i {
        font-size: 12px;
      }
    }

    .nav-title {
      font-size: 14px;
    }
  }

  &.nav-process-block {
    $size: $height-xl;

    .nav-link {
      padding: 24px;
    }

    .nav-link-number {
      max-width: $size;
      width: $size;
      height: $size;
      line-height: $size;
      font-size: 32px;
    }

    .nav-link-body {
      line-height: 1.5rem;
    }

    .nav-title {
      font-size: 1.125rem;
    }
  }
}

//-----------------------------------------------------------------
// Colors
//
.nav-process-secondary {
  @include nav-process-colors($color-secondary, $color-pale-secondary);
}
.nav-process-gray {
  @include nav-process-colors($color-gray, $color-pale-gray);
}
.nav-process-dark {
  @include nav-process-colors($color-dark, $color-pale-dark);
}
.nav-process-success {
  @include nav-process-colors($color-success, $color-pale-success);
}
.nav-process-info {
  @include nav-process-colors($color-info, $color-pale-info);
}
.nav-process-warning {
  @include nav-process-colors($color-warning, $color-pale-warning);
}
.nav-process-danger {
  @include nav-process-colors($color-danger, $color-pale-danger);
}
.nav-process-pink {
  @include nav-process-colors($color-pink, $color-pale-pink);
}
.nav-process-purple {
  @include nav-process-colors($color-purple, $color-pale-purple);
}
.nav-process-brown {
  @include nav-process-colors($color-brown, $color-pale-brown);
}
.nav-process-cyan {
  @include nav-process-colors($color-cyan, $color-pale-cyan);
}
.nav-process-yellow {
  @include nav-process-colors($color-yellow, $color-pale-yellow);
}

.nav-process-secondary {
  &.nav-process-circle .nav-item {
    &.complete .nav-link::after {
      color: $color-text-secondary;
    }
  }

  &.nav-process-iconic .nav-item {
    .nav-link {
      color: $color-secondary;
    }

    &.complete .nav-link {
      color: $color-text-secondary;
    }
  }

  &.nav-process-block .nav-item {
    &.complete .nav-link {
      color: $color-text-secondary;
    }

    &.processing .nav-link-number {
      color: $color-text-secondary;
    }
  }
}
