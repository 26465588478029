.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  user-select: none;
  display: none;

  .pace-progress {
    background: $color-primary;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: $zindex-pace;
  }
}

.pace-inactive {
  display: none;
}

body[data-provide~='pace'] {
  .pace {
    display: block;
  }

  .pace-inactive {
    display: none;
  }
}

@each $name, $color in $colors {
  .pace-#{$name} .pace-progress {
    background: $color;
  }
}
