//-----------------------------------------------------------------
// Media list
//

$media-excludes: ':not(.media-list-header):not(.media-list-footer)';
.media-list {
  > .media#{$media-excludes},
  .media-list-body > .media {
    margin-bottom: 0;
  }

  &ul {
    padding-left: 0;
  }

  // Sortable
  .media .sortable-dot {
    margin: 0;
    margin-left: -5px;
    opacity: 0;
    border-radius: 2px;
    transition: 0.5s;
  }

  .media:hover .sortable-dot {
    opacity: 0.6;
  }

  // Selectable
  &[data-provide~='selectable'] .media#{$media-excludes} {
    cursor: pointer;
  }
}

.media-list-hover {
  > .media#{$media-excludes}:hover,
  .media-list-body > .media:hover {
    background-color: $color-bg-lighter;
  }
}

.media-list-divided {
  > .media#{$media-excludes},
  .media-list-body > .media {
    border-bottom: 1px solid $color-alpha-dark-divider;

    &:last-child {
      border-bottom: none;
    }
  }
}

.media-list-bordered {
  border: 1px solid $color-divider-light;
}

.media-list-striped {
  .media#{$media-excludes}:nth-child(even) {
    background-color: $color-bg-lighter;
  }
}

// Sizes
//
.media-list-xs .media {
  padding: 10px 16px;
}

.media-list-sm .media {
  padding: 12px 16px;
}

.media-list-lg .media {
  padding: 20px 16px;
}

.media-list-xl .media {
  padding: 24px 20px;
}

.media-list-xxl .media {
  padding: 32px 24px;
}

// Header and footer
//
.media-list-header {
  border-bottom: 1px solid $color-divider;
  background-color: $color-bg-lightest;
}

.media-list-footer {
  border-top: 1px solid $color-divider;
  background-color: $color-bg-lightest;
}

//-----------------------------------------------------------------
// Media grid
//
.media-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;

  &::after {
    content: '';
    flex: auto;
  }

  &.row {
    margin-left: -15px;
    margin-right: -15px;

    .media {
      margin-bottom: 15px;
    }
  }

  > .media {
    margin-right: 15px;
    margin-bottom: 15px;
    width: 200px;
  }
}

.media-grid-bordered {
  .media {
    border: 1px solid $color-divider;
  }
}

.media-grid-hover {
  .media:hover {
    background-color: $color-bg-lighter;
  }
}
